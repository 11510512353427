import { Button } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { action, observable } from 'mobx';
import React from 'react';
import type { BaseData, CommonData, DictData, IMainSubStructureModel, MainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { RenderByPermission, request, SearchListModal } from '../../utils';
import { UserTypeDict } from '../../utils/dict';
import { API } from './api';
import { UserBan } from './modal/userBan';
import UserBanModel from './modal/userBanModel';
import UserDetailModel from './modal/userDetailModel';
import UserIdentityModel from './modal/userIdentityModel';

interface TargetData {
  pageNum: number;
  pageSize: number;
  total: number;
  totalPage: number;
  list: TargetList[];
}
interface TargetList {
  id: string;
  targetName: string;
  targetSequence: number;
}

export default class Model {
  constructor() {
    this.getDictOptions('channel_type');
    this.getTargetList();
    this.pageStore.grid.onQuery();
  }

  /* 需求列表 */
  @observable
  public targetList: Array<{ label: string; value: string }> = [];

  @action
  public getTargetList = () => {
    request<BaseData<TargetData>>({
      url: API.fetchTargetListPage,
      method: 'POST',
      data: {
        pageNum: 1,
        pageSize: 999,
      },
    })
      .then((res) => {
        this.targetList = res.data.list.map((item: any) => ({
          label: item.targetName,
          value: item.id,
        }));
        this.pageStore.programme.filterItems.addDict({ targetId: this.targetList });
      })
      .finally(() => {});
  };

  @observable
  public total = 0;

  public UserDetailStore = new UserDetailModel({ parent: this });

  public UserBanStore = new UserBanModel({ parent: this });

  public UserIdentityStore = new UserIdentityModel({ parent: this });

  @observable public channelOptions: Array<{ label: string; value: string }> = [];

  public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        label: '用户ID',
        field: 'userId',
      },
      {
        type: 'input',
        label: '用户昵称',
        field: 'nickName',
      },
      {
        type: 'input',
        label: '手机号',
        field: 'mobile',
      },
      {
        type: 'dateRange',
        label: '注册时间',
        field: 'time',
        format: 'YYYY-MM-DD',
      },
      {
        type: 'select',
        label: '状态',
        field: 'bizAccountStatus',
        data: [
          {
            label: '正常',
            value: '1',
          },
          {
            label: '封禁',
            value: '0',
          },
        ],
      },
      {
        type: 'select',
        label: '需求类型',
        field: 'targetId',
        data: this.targetList || [],
      },
      {
        type: 'select',
        label: '渠道来源',
        field: 'channelValue',
      },
      {
        type: 'input',
        label: '运营人员',
        field: 'sourceName',
      },
    ],
  };

  @action
  public setCollectData = () => {
    this.pageStore.grid.collectData = [
      {
        name: '总量',
        value: this.total || 0,
        color: 'blue',
      },
    ];
  };

  public grid: IMainSubStructureModel = {
    collectData: [
      {
        name: '总量',
        value: this.total,
        color: 'blue',
      },
    ],
    grid: {
      columns: [
        {
          key: 'operate',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <div>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <Button
                    onClick={() => this.UserDetailStore.onShow(row)}
                    type="link"
                  >
                    详情
                  </Button>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_12`}>
                  <UserBan
                    row={row}
                    store={this.UserBanStore}
                  />
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    onClick={() => this.UserIdentityStore.onShow(row)}
                    type="link"
                  >
                    编辑
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'id',
          name: 'ID',
        },
        {
          key: 'nickName',
          name: '用户',
        },
        {
          key: 'userType',
          name: '身份',
          formatter: ({ row }) => UserTypeDict.find((item) => item.dictValue === row.userType)?.dictName,
        },
        {
          key: 'createTime',
          name: '注册时间',
          formatter: ({ row }) => dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          key: 'groupCount',
          name: '进群数量',
        },
        {
          key: 'orderAmount',
          name: '付费金额',
        },
        {
          key: 'targetName',
          name: '需求类型',
          // formatter: ({ row }) => {
          //   if (row.targetId) {
          //     return this.targetList.find((item) => item.value === row.targetId)?.label;
          //   }
          //   return '';
          // },
        },
        {
          key: 'latestLoginIp',
          name: 'IP地址',
        },
        {
          key: 'latestLoginTime',
          name: '登录时间',
          formatter: ({ row }) => dayjs(row.latestLoginTime).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
          key: 'channelValueName',
          name: '渠道来源',
        },
        {
          key: 'userSourceName',
          name: '运营人员',
        },
        {
          key: 'bizAccountStatus',
          name: '帐号状态',
          formatter: ({ row }) => {
            if (row.bizAccountStatus === 1) {
              return '正常';
            }
            if (row.bizAccountStatus === 0) {
              return '封禁';
            }
            return '';
          },
        },
      ].map((item) => ({
        resizable: true,
        sortable: false,
        ...item,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
    },
    api: {
      onQuery: (params) => {
        const { filterParams, pageNum, pageSize } = params;
        const timeObj: { startTime?: string; endTime?: string } = {};
        if (filterParams.time) {
          const times = filterParams.time.split(',');
          if (times.length === 2) {
            timeObj.startTime = new Date(times[0]).valueOf().toString();
            timeObj.endTime = new Date(times[1]).valueOf().toString();
          }
          delete filterParams.time;
        }
        const postParams = {
          ...filterParams,
          ...timeObj,
          pageNum,
          pageSize,
        };

        return request<BaseData<any>>({
          url: API.fetchUserList,
          method: 'POST',
          data: postParams,
        });
      },
      callbackAfterQuery: (_this: MainSubStructureModel) => {
        this.total = _this.gridModel.total;
        this.setCollectData();
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 查询字典数据
  @action
  public getDictOptions = async (dictType: string) => {
    const req = await request<CommonData<DictData[]>>({
      method: 'GET',
      url: '/quan/sys/dict/type',
      params: { dictType },
    });
    const data = (req?.data || [])?.map((item) => ({
      label: item.dictName,
      value: item.dictValue,
    }));
    switch (dictType) {
      case 'channel_type': {
        this.channelOptions = data;
        this.pageStore.programme.filterItems.addDict({ channelValue: data });
        break;
      }
    }
  };
}
